const template = require('./datepick.html');
require('./datepick.css');
const isHoursOptionAllowed = require('./datepick-hour-config.js').isHoursOptionAllowed;
// pbm: reservation for coupled night & hour
/**
* To test:
* after switch toggle booking mode, errors e.g. duration error won't persist
*/
const cbModelRoomType = require('../../../lib/cloudbeds/model/room_type.js');
// const cbModelDate = require('../../../lib/cloudbeds/model/date');
// const Coupon = require('../../../lib/types/coupon');

// const guestMapper = require('../../../lib/mapper/guest');
const guestSchema = require('../../../lib/types/guest');

// const ajv = require('../../../lib/ajv');

const DatePickCtrl = (
    $scope,
    $location,
    ReserveDetailsSrvc,
    RoomAvailabilitySrvc,
    DiscountSrvc,
    CouponSrvc,
    APISrvc,
    FlowSrvc,
    PaymentSrvc,
    UserSrvc
) => {
  // --------------------------------------
  // | Loading parameters from url, and call method/service by the name of parameter
  // | TO DO: create a service to manage
  // --------------------------------------
  $scope.vm.has_overnight = APP_CONFIG.OPERATIONS.HAS_OVERNIGHT;
  // $scope.setLang('de');
  const urlParams = _.pick($location.search(), ['hour', 'night', 'coupon', 'hmac_email', 'hmac_hash']);
  // dirty hack for accept bookingType while clean it up. rely on reloadOnSearch = false
  // console.log('----- from param', urlParams);
  if (urlParams.hour || $scope.vm.has_overnight == false) {
    $scope.vm.bookingType = 'hour';
    $location.search('hour', null);
  } else if (urlParams.night) {
    $scope.vm.bookingType = 'night';
    $location.search('night', null);
  }
  if (urlParams.hmac_email && urlParams.hmac_hash) {
    const {hmac_email, hmac_hash} = urlParams;
    $scope.vm.userDetail = RoomAvailabilitySrvc.getUserDetails({hmac_email: hmac_email, hmac_hash: hmac_hash}).then(function(res) {
      console.log(res);
      UserSrvc.set(res.data);
    });
  }
  if (urlParams.coupon) {
    // console.log(urlParams.coupon);
    CouponSrvc.setCouponCode(urlParams.coupon);
    console.log(CouponSrvc.getCouponCode());
    console.log('``````````````');
  }

  let reserveDetails = ReserveDetailsSrvc.get();

  // --------------------------------------
  // | All variables inside $scope/$scope.vm
  // --------------------------------------
  $scope.vm.currency = APP_CONFIG.OPERATIONS.CURRENCY;
  $scope.vm.availableHoursForToday = [];
  $scope.vm.bookingSteps = APP_CONFIG.BOOKING_STEPS_LABELS;
  $scope.vm.bookingStepsUrl = APP_CONFIG.BOOKING_STEPS_URLS;
  // if set already, 1. bookingType 2. start end
  // we might wanna skip refreshing the date here? but wt if availability updated
  $scope.vm.bookingType = reserveDetails.reservation.bookingType || $scope.vm.bookingType || 'null';
  $scope.vm.isCheckingAvailability = false;
  $scope.vm.isCheckedOverNight = false;
  $scope.vm.isCheckedHourly = false;
  $scope.vm.showGenderPick = APP_CONFIG.OPERATIONS.SHOW_GENDER_PICK;
  $scope.vm.isHoursOptionAllowed = isHoursOptionAllowed;
  $scope.vm.presentRoomType = cbModelRoomType.present;
  $scope.vm.isCheckAvailability = [];
  $scope.vm.isMaxParty = false;
  $scope.vm.male = 0;
  $scope.vm.female = 0;
  if ($scope.vm.showGenderPick == false) {
    // default one male for book
    $scope.vm.male = 1;
  }
  // TO DO: check timezone === hk? should set @index.js or somewhere else
  $scope.vm.timezone = APP_CONFIG.LOCATION_INFO.TIMEZONE;
  $scope.vm.gmt = APP_CONFIG.LOCATION_INFO.GMT;
  $scope.vm.today = moment().format('YYYY.MM.DD (ddd)'); // .tz($scope.vm.timezone);

  $scope.vm.supportContact = {
    email: APP_CONFIG.SUPPORT_CONTACT.EMAIL,
    tel: APP_CONFIG.SUPPORT_CONTACT.TEL,
  };

  $scope.vm.isDev = process.env.NODE_ENV === 'DEV';

  $scope.vm.setReserveHourly = function(hoursOption) {
    $scope.vm.duration = hoursOption.duration;
    $scope.vm.roomprices = hoursOption.rate;
    $scope.vm.multiplier = hoursOption.multiplier;
    $scope.vm.spacePrice = hoursOption.spacePrice;
    console.log($scope.vm.spacePrice);
    console.log($scope.vm.multiplier + '$scope.vm.multiplier ');
  };

  function _initDateRange(start, end) {
    const defaultStart = moment().hour(15).minute(0);
    const defaultEnd = moment().hour(11).minute(0).add(1, 'days');
    start = (start || defaultStart);
    end = (end || defaultEnd);
    return {
      start,
      end,
      get diff() {
        return moment(end).startOf('day').diff(moment(start).startOf('day'), 'days');
      },
      set startDate(value) {
        start = value;
      },
      set endDate(value) {
        end = value;
      },
    };
  }

  function _initTimeRange(start, end) {
    console.log('_initTimeRange');
    console.log(start);
    console.log(end);
    start = start || null;
    end = end || null;
    // checkin range 9:00am - 11:00pm, 15min step
    // no default
    return {
      // TODO nearest next 30 step
      start,
      end,
      get diff() {
        return moment(end).diff(moment(start), 'hours');
      },
      get hoursToDayEnd() {
        // 2359
        return moment().endOf('day').diff(moment(start), 'hours') + 1;
      },
      _toCalendar: function(date) {
        return moment(date).calendar(null, {sameDay: '[Today]', nextDay: '[Tomorrow]', sameElse: 'DD/MM/YYYY'});
      },
      get startToCalendar() {
        return this._toCalendar(start);
      },
      get endToCalendar() {
        return this._toCalendar(end);
      },
    };
  }
  // TODO refactor
  // safeguard form invalid issue
  // pbm here is we saved only reservation but not the UI state after view refreshed
  if ($scope.vm.new === 'true') {
    $scope.vm.dateRange = _initDateRange();
    $scope.vm.timeRange = _initTimeRange();
  } else if ($scope.vm.bookingType === 'night') {
    $scope.vm.dateRange = _initDateRange(
        _.get(reserveDetails, 'reservation.startDate'), _.get(reserveDetails, 'reservation.endDate')
    );
    $scope.vm.timeRange = _initTimeRange();
    $scope.vm.duration = _.get(reserveDetails, 'reservation.numHour');
  } else {
    $scope.vm.dateRange = _initDateRange();
    $scope.vm.timeRange = _initTimeRange(
        _.get(reserveDetails, 'reservation.startDate'), _.get(reserveDetails, 'reservation.endDate')
    );
    $scope.vm.duration = _.get(reserveDetails, 'reservation.numHour');
  }

  $scope.vm.hoursOptions = APP_CONFIG.OPERATIONS.HOURLY_TYPE;
  $scope.vm.nightOptions = APP_CONFIG.OPERATIONS.OVERNIGHT_TYPE;

  function _updateHoursOptionsWithRate() {
    console.log('check hourly rate');
    $scope.vm.isCheckAvailability = RoomAvailabilitySrvc.checkHourlyRoomRate(APP_CONFIG.OPERATIONS.EXP_SITE_ID)
        .then(function(data) {
          const localeWeek = Number.parseInt(moment($scope.vm.timeRange.start).format('d'));
          // const filter = _.filter(data.data.prices, {'week': localeWeek, 'duration': duration});
          $scope.vm.hoursOptionsWithRate = _.map($scope.vm.hoursOptions, function(hourOption) {
            // console.log($scope.vm.timeRange.start);
            const a = moment($scope.vm.timeRange.start).utcOffset(8).add(hourOption, 'hour');
            const b = moment($scope.vm.timeRange.start).utcOffset(8).hour(APP_CONFIG.OPERATIONS.HOURLY_CLOSE_AT).minute(0);
            console.log(a.format() + ' ' + b.format());
            const isBeforeClose = a.isBefore(b, 'second');
            console.log(isBeforeClose + 'isbeforeclose');
            const filter = _.filter(data.data.prices, function(o) {
              // console.log(o.week, localeWeek, o.duration, hourOption);
              return o.week==localeWeek&&o.duration==hourOption;
            });
            const roomRate = _.get(filter, ['0', 'price']);
            const multiplier = _.get(filter, ['0', 'multiplier']);
            const spacePrice = _.get(filter, ['0']);
            const hrWithRate = {
              duration: hourOption,
              isBeforeClose: isBeforeClose,
              rate: roomRate,
              multiplier: multiplier,
              spacePrice: spacePrice,
            };
            console.log(hrWithRate);
            return hrWithRate;
          });
          $scope.vm.isCheckedHourly = true;
        });
  }

  function calculateDiff() {
    const duration = $scope.vm.duration;
    // console.log($scope.vm.bookingType === 'hour', $scope.vm.bookingType);
    if ($scope.vm.bookingType === 'hour') {
      console.log(moment($scope.vm.timeRange.start).add(duration, 'hours').toDate(), 'cal hour');
      $scope.vm.timeRange.end = moment($scope.vm.timeRange.start).add(duration, 'hours').toDate();
    };
  }

  // --------------------------------------
  // Watcher(s), should avoid to use too much watchers becoz of the performance issue (angularjs only)
  // ref. https://dzone.com/articles/9-ways-to-improve-angularjs-performance
  // ref. https://stackoverflow.com/questions/9682092/how-does-data-binding-work-in-angularjs
  // --------------------------------------
  $scope.$watch('vm.bookingType', function() {
    const expression = $scope.vm.bookingType;
    console.log(expression, 'expression');
    switch (expression) {
      case 'hour':
        $scope.vm.dateRange = _initDateRange();
        _updateHoursOptionsWithRate();
        break;
      case 'night':
        // $scope.vm.timeRange = _initTimeRange();
        break;
      default:
        $scope.vm.dateRange = _initDateRange();
        $scope.vm.timeRange = _initTimeRange();
    }
  });
  $scope.$watch('vm.timeRange.start', function() {
    if ($scope.vm.bookingType === 'hour') {
      calculateDiff();
      _updateHoursOptionsWithRate();
    }
  });

  $scope.$watch('vm.dateRange.start', function() {
    $scope.vm.dateRange.startDate = $scope.vm.dateRange.start;
    $scope.vm.isCheckedOverNight = false;
    $scope.vm.isCheckedHourly = false;
    $scope.vm.duration = null;
    console.log('start date change');
  });
  $scope.$watch('vm.dateRange.end', function() {
    $scope.vm.dateRange.endDate = $scope.vm.dateRange.end;
    $scope.vm.isCheckedOverNight = false;
    $scope.vm.isCheckedHourly = false;
    $scope.vm.duration = null;
    console.log('end date change');
  });
  $scope.$watch('vm.duration', function() {
    console.log('duration');
    calculateDiff();
    console.log($scope.vm.dateRange.start.format(), $scope.vm.bookingType, 'watch duration');
    console.log($scope.vm.dateRange.end.format());

    if ($scope.vm.bookingType === 'night') {
      $scope.vm.isCheckedHourly = false;
    }
  });


  function _getRoomRate() {
    return $scope.vm.roomprices;
  }

  function _getDatetimeDetails(bookingType, duration) {
    // Also depends on do we differentiate night vs nap at all
    // think about separate the forms as different validations
    if (bookingType === 'night') {
      const index = _.findIndex(APP_CONFIG.OPERATIONS.OVERNIGHT_TYPE, ['duration', duration]);
      const checkinAt = APP_CONFIG.OPERATIONS.OVERNIGHT_TYPE[index]['checkin_at'];
      const checkoutAt = APP_CONFIG.OPERATIONS.OVERNIGHT_TYPE[index]['checkout_at'];
      return {
        startDate: moment($scope.vm.dateRange.start).set('hour', checkinAt),
        endDate: moment($scope.vm.dateRange.end).set('hour', checkoutAt),
      };
    }
    return {
      startDate: moment($scope.vm.timeRange.start),
      endDate: moment($scope.vm.timeRange.end),
    };
  }
  // Custom validations as most elements became not form/input
  // Also need distinguish night/hour
  $scope.isFormValid = function() {
    console.log($scope.vm.bookingType === 'night' || ($scope.vm.timeRange.start && $scope.vm.duration > 0));
    return $scope.vm.bookingType === 'night' || ($scope.vm.timeRange.start && $scope.vm.duration > 0);
  };

  $scope.addParty = function(gender) {
    console.log('add party!');
    console.log(APP_CONFIG.OPERATIONS.MAX_PARTY_SIZE);
    console.log($scope.vm.male + $scope.vm.female);
    if ($scope.vm.male + $scope.vm.female < APP_CONFIG.OPERATIONS.MAX_PARTY_SIZE) {
      if (gender === 'male') {
        console.log('add male!');
        $scope.vm.male++;
      } else if (gender === 'female') {
        console.log('add female!');
        $scope.vm.female++;
      }
    } else {
      $scope.vm.isMaxParty = true;
    }
  };

  $scope.loadAvailabilityNight= function() {
    $scope.vm.isCheckAvailability = RoomAvailabilitySrvc.checkAvailabeRoomType({
      startDate: moment($scope.vm.dateRange.start).format('YYYY-MM-DD'),
      endDate: moment($scope.vm.dateRange.end).startOf('day').format('YYYY-MM-DD'),
      detailedRates: 'true',
      tokenType: APP_CONFIG.LAMBDA.TOKEN_TYPE,
      propertyIDs: APP_CONFIG.OPERATIONS.PROPERTYIDS,
    })
        .then(_.property('data'))
        .then(function(data) {
          RoomAvailabilitySrvc.setCloudbedsRoomType(data);
          $scope.vm.isCheckedOverNight = true;
        });
  };
  $scope.submit = function() {
    const actualGuestCount = $scope.vm.male + $scope.vm.female;
    reserveDetails = _.merge(
        {
          reservation: _.pick($scope.vm, ['bookingType', 'roomTypeID', 'duration', 'male', 'female']),
          guests: _.fill(Array(actualGuestCount), guestSchema.factory({})),
          duration: $scope.vm.duration,
        }, {
          reservation: _getDatetimeDetails($scope.vm.bookingType, $scope.vm.duration),
          multiplier: $scope.vm.multiplier || 1,
        }
    );
    console.log(reserveDetails);
    if ($scope.vm.bookingType === 'hour') {
      const amountPerHourPlan = _getRoomRate();
      console.log(amountPerHourPlan);
      $scope.vm.payment = PaymentSrvc.createPaymentHour(amountPerHourPlan);
      reserveDetails = _.merge(
          {
            reservation: {roomTypeID: APP_CONFIG.OPERATIONS.HOURLY_ROOMTYPEID[$scope.vm.duration.toString()]},
            payment: $scope.vm.payment,
            multiplier: $scope.vm.multiplier,
            spacePrice: $scope.vm.spacePrice,
          },
          reserveDetails
      );
      reserveDetails.guests = _.fill(Array(1), guestSchema.factory({}));
      FlowSrvc.skipSteps(1);
    }
    console.log(reserveDetails);
    // in here existing reservation is from cache, which should be reflected in vm already
    ReserveDetailsSrvc.set(reserveDetails);
    FlowSrvc.next();
  };
};

DatePickCtrl.$inject = [
  '$scope',
  '$location',
  'ReserveDetailsSrvc',
  'RoomAvailabilitySrvc',
  'DiscountSrvc',
  'CouponSrvc',
  'APISrvc',
  'FlowSrvc',
  'PaymentSrvc',
  'UserSrvc',
];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    onsubmit: '&',
    new: '@',
  },
  controller: DatePickCtrl,
};
