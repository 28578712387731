const template = require('./select-room.html');
require('./select-room.css');
const DatePickCtrl = (
    $scope,
    $location,
    ReserveDetailsSrvc,
    RoomAvailabilitySrvc,
    PaymentSrvc,
    FlowSrvc
) => {
  $scope.vm.currency = APP_CONFIG.OPERATIONS.CURRENCY;
  $scope.vm.bookingSteps = APP_CONFIG.BOOKING_STEPS_LABELS;
  $scope.vm.bookingStepsUrl = APP_CONFIG.BOOKING_STEPS_URLS;
  $scope.vm.startUrl = APP_CONFIG.SITE_URL;
  $scope.vm.timezone = APP_CONFIG.LOCATION_INFO.TIMEZONE;
  $scope.vm.gmt = APP_CONFIG.LOCATION_INFO.GMT;
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  console.log($scope.vm.reserveDetails, '0');
  $scope.vm.startDate = moment($scope.vm.reserveDetails.reservation.startDate).toDate();
  $scope.vm.endDate = moment($scope.vm.reserveDetails.reservation.endDate).toDate();
  const duration = $scope.vm.reserveDetails.reservation.duration;
  const man = $scope.vm.reserveDetails.reservation.male;
  const female = $scope.vm.reserveDetails.reservation.female;
  $scope.vm.totalGuest = man + female;
  const availableRoomType = filterAvailableRoomType(RoomAvailabilitySrvc, duration, man, female);
  $scope.vm.checkPrivateRoom = checkPrivateRoom;
  $scope.vm.roomDetails = availableRoomType;
  $scope.vm.ceil = ceil;
  $scope.submit = function() {
    console.log('submit');
    console.log($scope.vm.selectedRoom);
    console.log($scope.vm.reserveDetails, '1');
    const payment = updateNightRate(PaymentSrvc, $scope.vm.selectedRoom, (man+female), checkPrivateRoom($scope.vm.selectedRoom.roomTypeNameShort));
    console.log(payment);
    $scope.vm.reserveDetails = _.merge(
        $scope.vm.reserveDetails,
        {
          reservation: _.pick($scope.vm.selectedRoom, ['roomTypeID']),
          payment: payment,
        }
    );
    console.log(_.merge(
        $scope.vm.reserveDetails,
        {
          reservation: _.pick($scope.vm.selectedRoom, ['roomTypeID']),
          payment: payment,
        }
    ));
    console.log($scope.vm.reserveDetails, '2');
    ReserveDetailsSrvc.set($scope.vm.reserveDetails);
    console.log(ReserveDetailsSrvc.get());
    FlowSrvc.next();
  };
};
DatePickCtrl.$inject = [
  '$scope',
  '$location',
  'ReserveDetailsSrvc',
  'RoomAvailabilitySrvc',
  'PaymentSrvc',
  'FlowSrvc',
];
// $scope.vm.timeRange.end =moment();
module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    onsubmit: '&',
    new: '@',
  },
  controller: DatePickCtrl,
};
function checkPrivateRoom(key) {
  const privateRoomFlag = APP_CONFIG.OPERATIONS.PRIVATE_ROOM_FLAG;
  if (_.includes(privateRoomFlag, key)) {
    return true;
  } else {
    return false;
  }
}

function updateNightRate(PaymentSrvc, roomType, totalunit, privateRoom) {
  console.log('update night rate.');
  if (privateRoom) {
    totalunit = 1;
  }
  const payment = PaymentSrvc.createPaymentNight(roomType.roomRateDetailed, totalunit);
  console.log(payment);
  return payment;
}

function ceil(cal) {
  return Math.ceil(cal);
}

function filterAvailableRoomType(RoomAvailabilitySrvc, duration, man, female) {
  const cloudbedsRoomType = RoomAvailabilitySrvc.getCloudbedsRoomType();
  let availableRoomType = cloudbedsRoomType.filter(function(o) {
    console.log(APP_CONFIG.OPERATIONS.OVERNIGHT_TYPE);
    const index = _.findIndex(APP_CONFIG.OPERATIONS.OVERNIGHT_TYPE, ['duration', duration]);
    const type = APP_CONFIG.OPERATIONS.OVERNIGHT_TYPE[index]['cb-roomTypeName'];
    console.log(o.roomTypeName.search(type), o.roomTypeName, duration, type);
    // party booking or room available lower than total people
    const availableMatch = o.roomsAvailable >= (man+female) || o.adultsIncluded >= (man+female);
    console.log(o.roomsAvailable + 'o.roomsAvailable' + (man+female) + 'man+female' + o.adultsIncluded);
    console.log(availableMatch, o.roomTypeName.search(type) != -1);
    console.log(availableMatch && o.roomTypeName.search(type) != -1);
    return availableMatch && o.roomTypeName.search(type) != -1;
  });
  if (APP_CONFIG.OPERATIONS.EXIST_FEMALE_ONLY_SLPER) {
    const femaleFlag = APP_CONFIG.OPERATIONS.FEMALE_ONLY_FLAG;
    if (man >= 1) {
      availableRoomType = availableRoomType.filter(function(o) {
        console.log(o.roomTypeNameShort.search(femaleFlag), o.roomTypeNameShort, femaleFlag);
        return o.roomTypeNameShort.search(femaleFlag) == -1;
      });
    }
  }
  console.log(availableRoomType);
  return availableRoomType;
}

