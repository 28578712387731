require('./datepicker.css');

const template = require('./datepicker.html');
const cbModelDate = require('../lib/cloudbeds/model/date');
const timeUtil = require('./time_util.js');

/**
* Considerations: selected, min, Max Date shd all be in terms of HK
*/

/** start /end have different behavior
- unavailable day can be checkout
- test: cannot in between unavailable day
- TODO reload predicate after startDate changed
**/
const DatePickerCtrl = function($scope) {
  $scope.vm.offset = moment().format('ZZ');
  function isAvailableDate(momentDate) {
    return !_.includes($scope.vm.unavailableDates, cbModelDate.create(momentDate));
  }
  console.log(moment().format(), 'format');
  $scope.vm.minDate = timeUtil.LocationTimeAsMidNightDateInUserTimeZone(moment());
  console.log($scope.vm.minDate, 'minDate');
  console.log(moment.tz.guess());
  // decouple the input as datepicker don't handle timezone well
  // moment is use as timezone and js Date is utc. Must add 1 day for buffer.
  $scope.vm.dateInput = {
    start: timeUtil.LocationTimeAsMidNightDateInUserTimeZone(_.get($scope, 'vm.date.start', moment())),
    end: timeUtil.LocationTimeAsMidNightDateInUserTimeZone(_.get($scope, 'vm.date.end', moment().add(1, 'days'))),
  };
  console.log($scope.vm.dateInput.start, 'start');
  console.log($scope.vm.dateInput.end, 'end');

  // when input update, update also vm but convert back with original hours

  $scope.$watch('vm.dateInput.start', function(n, o) {
    // $scope.vm.dateInput.start = moment(n).add(7, 'days').toDate();
    console.log('start: n: '+n+'; o: '+'o');
    // skip the init case where start / endDate both passed
    $scope.vm.form.endDate.$setPristine(true);
    if ($scope.vm.form.endDate.$pristine && n !== o) {
      $scope.vm.dateInput.end = moment(n).add(1, 'days').toDate();
    }
    $scope.vm.date.start = _asLocationStartOfDay($scope.vm.dateInput.start);
    // $scope.vm.date.start = $scope.vm.dateInput.start;
    console.log('start date: vm.dateInput.start');
    console.log($scope.vm.dateInput.start);
    console.log('start date: vm.date.start');
    console.log($scope.vm.date.start.format());
  });

  $scope.$watch('vm.dateInput.end', function() {
    // $scope.vm.dateInput.end = moment(n).add(7, 'days').toDate();
    $scope.vm.date.end = _asLocationStartOfDay($scope.vm.dateInput.end);
    // $scope.vm.date.end = $scope.vm.dateInput.end;
    console.log('end date: vm.dateInput.end');
    console.log($scope.vm.dateInput.end);
    console.log('end date: vm.date.end');
    console.log($scope.vm.date.end.format());
  });

  // refer to https://github.com/weSleeep/exp/issues/75
  $scope.vm.maxStartDate = timeUtil.LocationTimeAsMidNightDateInUserTimeZone(
      moment().add(APP_CONFIG.OPERATIONS.MAX_ADVANCE_BOOK_MONTHS, 'months').subtract(1, 'days')
  );
  $scope.vm.maxEndDate = timeUtil.LocationTimeAsMidNightDateInUserTimeZone(
      moment().add(APP_CONFIG.OPERATIONS.MAX_ADVANCE_BOOK_MONTHS, 'months')
  );
  $scope.vm.timezone = APP_CONFIG.LOCATION_INFO.TIMEZONE;
  $scope.vm.gmt = APP_CONFIG.LOCATION_INFO.GMT;

  function _asLocationStartOfDay(date) {
    return moment(
        {
          y: date.getFullYear(),
          M: date.getMonth(),
          d: date.getDate(),
        }).startOf('day');
  }
  /**
   * @param {date} used by datepicker is of @type {Date},
   * which is in user's timezone.
   * It's required to be configured explicitly
   * @see {@link https://material.angularjs.org/latest/api/directive/mdDatepicker}.
   * However seems the date passed to predicate is the original not-yet-offset one.
   * Meanwhile all our system are based in Local time (incl those unavailableDates)
   *  & moment(date) will change it to Local time,
   * so here we must reconstruct date
   * @see Timezone section in README.md
   */
  $scope.vm.startDatePredicate = function(date) {
    return isAvailableDate(_asLocationStartOfDay(date));
  };
  $scope.vm.endDatePredicate = function(date) {
    // diff: calculated based on timezone
    // date at angular become midNight
    // angular will do this date = this.dateUtil.createDateAtMidnight(date);
    const startDay = moment($scope.vm.date.start).startOf('day');
    const diff = moment(date).diff(startDay, 'days');
    const unavailableInBetween = _.filter($scope.vm.unavailableDates, function(unavailableDate) {
      return moment(unavailableDate).startOf('day') >= startDay &&
          moment(unavailableDate).startOf('day') < _asLocationStartOfDay(date);
    }).length > 0;
    return isAvailableDate(
        _asLocationStartOfDay(date).subtract(1, 'days'))
         && !unavailableInBetween && (diff > 0)
         && (diff <= APP_CONFIG.OPERATIONS.MAX_CONTINUOUS_BOOK_DAYS
         );
  };
};

DatePickerCtrl.$inject = ['$scope'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    date: '=',
    unavailableDates: '=',
    form: '=',
  },
  controller: DatePickerCtrl,
};
