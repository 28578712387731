/**
 * V2 of reserve service using new data structure
 * Simple in-memory Cache. Localstorage etc are handle at route-level
 * It's type-safe to add extra fields e.g. numPerNight / checkinUrl to @ReservationDetail,
 * however they might not be persisted
 * Decided not to go with getters/setters as make saving a more conscious choice (esp at partial set)
 * Decided not to go with ImmutableJS as by nature the vm / cachce is global mutable state
 * Memory usage increased with the duplication but fine
 */
const ReserveDetails = require('../lib/types/reserve-details');
const schema = ReserveDetails.schema;
const ajv = require('../lib/ajv');

const Reservation = require('../lib/types/reservation');
const Guest = require('../lib/types/guest');

function ReserveDetailsSrvc(store, $window) {
  // a bit hacky, think again about this state
  let _isInitialized = false;
  // TO DO  move this to datepick the only place needing it
  function _asDefault() {
    return new ReserveDetails.type({
      gender: null,
      currency: APP_CONFIG.OPERATIONS.CURRENCY,
      isPrecheckin: false,
      // price: {}
    }, null);
  }
  let _details = _asDefault();
  return {
    get: function() {
      // TO DO if type check fail, return undefined? but needa clean up as well
      return _.cloneDeep(_details);
    },
    update: function(details) {
      _isInitialized = true;
      if (!details) {
        return _details;
      }

      // since we accept partial, we should update only when result is valid
      _.merge(_details, {
        reservation: Reservation.factory(details.reservation),
        guests: Guest.factory(details.guests),
        payment: details.payment,
        couponCode: _.get(details, 'couponCode', _details.couponCode),
        multiplier: details.multiplier,
        spacePrice: details.spacePrice,
      });

      if (_details.guest && _.get(_details, 'guest.email')) {
        if ($window.analytics) {
          $window.analytics.identify(_details.guest.email, {
            name: _details.guest.firstName + _details.guest.lastName,
            email: _details.guest.email,
            country: _details.guest.country,
            pmsGuestId: _details.guest.pmsGuestId,
          });
        }
      }

      store.set(APP_CONFIG.RESERVE_DETAILS_CACHE_KEY, _details);
    },
    set: function(details) {
      _details = _asDefault();
      this.update(details);
    },
    isInitialized: function() {
      return _isInitialized;
    },
    // validate user with guest details
    validate: function() {
      const data = this.get();
      const resStartDate = _.get(data, 'startDate', moment());
      const resEndDate = _.get(data, 'endDate', moment());

      const isDatesCorrect = resEndDate > resStartDate && resStartDate > moment().startOf('day');
      return ajv.validate(schema, data) && isDatesCorrect;
    },
    // TO DO: create a Reservation service, and store it there
    validateReservation: function(reservation) {
      const _schema = schema.properties.reservation;
      // let resStartDate = _.get(reservation, 'startDate', moment());
      // let resEndDate = _.get(reservation, 'endDate', moment());

      // var isDatesCorrect = resEndDate > resStartDate && resStartDate > moment().startOf('day');
      // return (reservation) ? (ajv.validate(_schema, reservation) && isDatesCorrect) : false;
      return ajv.validate(_schema, reservation);
    },
  };
}

ReserveDetailsSrvc.$inject = ['store', '$window'];
module.exports = ReserveDetailsSrvc;
