/**
* Refer to types/reserve-detail.js for overall type design
*/

const factory = function(reservation) {
  const _reservation = _.cloneDeep(reservation);
  if (_.isEmpty(_reservation)) {
    return;
  }
  // deserialize ISO string to moment
  if (_reservation.startDate) {
    _reservation.startDate = moment(_reservation.startDate);
    _reservation.tempStart = _.cloneDeep(_reservation.startDate).startOf('day');
  }
  if (_reservation.endDate) {
    _reservation.endDate = moment(_reservation.endDate);
    _reservation.tempEnd = _.cloneDeep(_reservation.endDate).startOf('day');
  }

  // can be proper type definition
  Object.defineProperty(_reservation, 'numNight', {
    get: function() {
      if (!this.endDate || !this.startDate) {
        return 0;
      }
      // given fixed checkin checkout time no edge case now, always +1
      return this.tempEnd.diff(this.tempStart, 'days');
    },
    enumerable: true,
  });
  /*   Object.defineProperty(_reservation, 'roomTypeID', {
      get: function() {
        if (!this.roomTypeID) {
          return 0;
        }
        return this.roomTypeID;
      },
      enumerable: true,
    }); */
  Object.defineProperty(_reservation, 'numHour', {
    get: function() {
      if (!this.endDate || !this.startDate) {
        return 0;
      }
      // return this.endDate.diff(this.startDate, 'hours');
      return this.endDate.diff(this.startDate, 'minutes') / 60;
    },
    enumerable: true,
  });

  // Client-facing id
  Object.defineProperty(_reservation, 'semanticId', {
    get: function() {
      return this.pmsReservationId || this.id;
    },
    enumerable: true,
  });

  return _reservation;
};

const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    pmsReservationId: {
      type: 'string',
    },
    // Current design this is never persisted, used in booking flow 's UI/ email
    // generate new one using reservation id if need
    checkinUrl: {
      type: 'string',
      format: 'uri',
    },
    // as moment object for easier manipulation, consider RFC3339 to stick w/ JSON schema
    // should use type moment instead
    startDate: {
      type: 'object',
      instanceof: 'moment',
    },
    endDate: {
      type: 'object',
      instanceof: 'moment',
    },
    bookingType: {
      oneOf: [
        {type: 'string', pattern: 'hour'},
        {type: 'string', pattern: 'night'},
      ],
    },
    duration: {
      type: 'number',
    },
    numHour: {
      type: 'number',
    },
    numNight: {
      type: 'number',
    },
  },
  required: ['startDate', 'endDate', 'bookingType'],
};

module.exports = {
  schema: schema,
  factory: factory,
};
// checkinUrl
