// endDate's minDate is startDate
require('./timepicker.css');

const timeUtil = require('./time_util.js');
const template = require('./timepicker.html');
// const timeUtil = require('./time_util.js');
const timeValueFormat = 'YYYYMMDDHH:mm';
const TimePickerCtrl = function($scope) {
  $scope.$watch('vm.selectedTime', function() {
    if (!$scope.vm.timeRange || !$scope.vm.selectedTime) {
      return;
    }
    const startTime = moment($scope.vm.selectedTime, timeValueFormat);
    $scope.vm.timeRange.start = startTime.isValid() ? startTime.toDate() : null;
  });
  $scope.vm.minStartDate = timeUtil.LocationTimeAsMidNightDateInUserTimeZone(
      moment()
  );
  $scope.vm.maxEndDate = timeUtil.LocationTimeAsMidNightDateInUserTimeZone(
      moment().add(7, 'days')
  );
  $scope.vm.timezone = APP_CONFIG.LOCATION_INFO.TIMEZONE;
  $scope.vm.gmt = APP_CONFIG.LOCATION_INFO.GMT;
  // dirty workaround before actual impl for min/max
  const today = moment().startOf('day');
  /*   $scope.$evalAsync(function() {
    $scope.vm.selectedTime = moment($scope.vm.timeRange.startDate+' '+$scope.vm.timeRange.startTime)
      .format(timeValueFormat) || null;
  }); */
  $scope.$evalAsync(function() {
    $scope.vm.dateInput = today;
  });
  $scope.$watch('vm.dateInput', function() {
    $scope.vm.timeRange.start = null;
    $scope.vm.timeRange.end = null;
    $scope.vm.duration = null;
    a=moment($scope.vm.dateInput);
    $scope.vm.options=[];
    $scope.vm.options = _.range(APP_CONFIG.OPERATIONS.HOURLY_START_AT * 4, Math.min(APP_CONFIG.OPERATIONS.HOURLY_CLOSE_AT, 24) * 4).map(function(t) {
      return moment($scope.vm.dateInput).add(t * 0.25, 'hour');
    })
        .filter(function(t) {
          return t.diff(moment())>0;
        })
        .map(function(t) {
          return {
            title: t.format('HH:mm'),
            value: t.format(timeValueFormat),
          };
        });
    $scope.vm.selectedTime = moment().startOf('hour').add(1, 'hour').format(timeValueFormat);
  });
};
TimePickerCtrl.$inject = ['$scope'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    allHours: '=',
    timeRange: '=',
    minTime: '@',
    maxTime: '@',
    base: '@',
    duration: '=',
    dateInput: '=',
  },
  controller: TimePickerCtrl,
};
