const template = require('./confirm.html');
require('./confirm.css');
const cbModelDate = require('../../../lib/cloudbeds/model/date');
const cbModelRoomType = require('../../../lib/cloudbeds/model/room_type.js');
const couponHelper = require('../../../lib/coupon.js');
const {
  drawPayCode,
} = require('../../../lib/payme/paycode.js');
const {
  qrcode,
} = require('../../../lib/payme/qrcode.js');

const ConfirmCtrl = ($scope, FlowSrvc, ReserveDetailsSrvc, DiscountSrvc, CouponSrvc, APISrvc, CONFIG, ModalService, StripeSrvc, PayMeSrvc, $rootScope, $window) => {
  console.log(ReserveDetailsSrvc.get());
  // Should not bind the FlowSrvc to vm??
  $scope.vm.flow = FlowSrvc;
  $scope.vm.dimmed = false;
  $scope.vm.loading = false;
  $scope.vm.siteName = APP_CONFIG.SITE_NAME;
  $scope.vm.showEXPPoints = APP_CONFIG.OPERATIONS.SHOW_EXP_POINTS;
  $scope.vm.bookingSteps = APP_CONFIG.BOOKING_STEPS_LABELS;
  $scope.vm.bookingStepsUrl = APP_CONFIG.BOOKING_STEPS_URLS;
  $scope.vm.tncUrl = APP_CONFIG.OPERATIONS.T_C_URL;
  $scope.vm.timezone = APP_CONFIG.LOCATION_INFO.TIMEZONE;
  $scope.vm.gtm = APP_CONFIG.LOCATION_INFO.GTM;
  $scope.vm.payby = APP_CONFIG.OPERATIONS.DEFAULT_PAYMENT_METHOD;
  $scope.vm.paymentList = APP_CONFIG.OPERATIONS.PAYMENT_METHOD_LIST;
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  $scope.vm.bookingType = $scope.vm.reserveDetails.reservation.bookingType;
  $scope.vm.startDate = moment($scope.vm.reserveDetails.reservation.startDate).toDate();
  $scope.vm.endDate = moment($scope.vm.reserveDetails.reservation.endDate).toDate();
  $scope.vm.isCouponMode = false;
  $scope.vm.isConfirmTnC = false;
  $scope.vm.isCouponApplied = false;
  $scope.vm.disablePaymentBtn = false;
  $scope.vm.couponCode = CouponSrvc.getCouponCode();
  $scope.vm.originalPrice = _.get($scope, 'vm.reserveDetails.payment.total');
  $scope.vm.stripeFailMsg = ''; // empty string === no error
  $scope.vm.paymeFailMsg = '';
  $scope.vm.expPoint = {
    pointsCurrencyRatio: APP_CONFIG.OPERATIONS.POINTS_CURRENCY_RATIO,
    pointsMultiplier: $scope.vm.reserveDetails.multiplier,
  };
  $scope.vm.points = Math.floor($scope.vm.reserveDetails.payment.total / $scope.vm.expPoint.pointsCurrencyRatio * $scope.vm.expPoint.pointsMultiplier);
  $scope.vm.getPlan = cbModelRoomType.getPlan($scope.vm.reserveDetails.reservation.duration);
  $scope.vm.paymelogo = 'https://assets.sleeep.io/icons/1x/businessLogo_300x300.png';
  // TO DO: create a Coupon Service / type into the couponHelper
  $scope.vm.hideModal = function() {
    if ($scope.vm.isNotMobile) {
      clearTimeout($scope.vm.timerId);
      clearInterval($scope.vm.intervalTimerId);
      const canvas = document.getElementById('payCodeCanvas');
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }
    $scope.vm.paymeFailMsg = '';
    $scope.vm.dimmed = false;
    $scope.vm.loading = false;
  };
  $scope.vm.isNotMobile = (function() {
    let check = false;
    (function(a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return !check;
  })();
  console.log('isNotMobile', $scope.vm.isNotMobile);
  console.log(navigator);

  $scope.vm.checkPayMePaymentStatus = function(callback) {
    const requestId = _.get($scope.vm.paymeResponse, 'paymentRequestId');
    if (requestId) {
      PayMeSrvc.checkPaymentStatus(requestId).then(callback);
    }
  };
  $scope.vm.triggerPayMeCallBack = function(res) {
        console.log(res.data);
        const result = res.data;
        if (res.data.errors) {
          $scope.vm.paymeFailMsg = 'EA002';
        }
        switch (result.statusCode) {
          case 'PR005':
            // _reserve(requestId);
            clearTimeout($scope.vm.timerId);
            clearInterval($scope.vm.intervalTimerId);
            FlowSrvc.next();
            break;
          case 'PR001':
            $scope.vm.paymeFailMsg = 'not_yet_complete';
            break;
          case 'PR007':
            $scope.vm.paymeFailMsg = 'paycode_expired';
            clearTimeout($scope.vm.timerId);
            clearInterval($scope.vm.intervalTimerId);
            break;
          case 'PR004':
            $scope.vm.paymeFailMsg = 'EA002';
            clearTimeout($scope.vm.timerId);
            clearInterval($scope.vm.intervalTimerId);
            break;
        }
      };
    $scope.vm.intervalPayMeCallBack = function(res) {
        console.log(res.data);
        const result = res.data;
        if (res.data.errors) {
          $scope.vm.paymeFailMsg = 'EA002';
        }
        switch (result.statusCode) {
          case 'PR005':
            // _reserve(requestId);
            clearTimeout($scope.vm.timerId);
            clearInterval($scope.vm.intervalTimerId);
            FlowSrvc.next();
            break;
          case 'PR007':
            $scope.vm.paymeFailMsg = 'paycode_expired';
            clearTimeout($scope.vm.timerId);
            clearInterval($scope.vm.intervalTimerId);
            break;
          case 'PR004':
            $scope.vm.paymeFailMsg = 'EA002';
            clearTimeout($scope.vm.timerId);
            clearInterval($scope.vm.intervalTimerId);
            break;
        }
      };
  $scope.vm.checkCoupon = function() {
    $scope.vm.isCouponFailed = false;

    function _applyDiscount(discountType, discountValue) {
      $scope.vm.isCouponApplied = true;
      $scope.vm.isCouponFailed = false;
      let discountedAmount = 0;
      if (_.toLower(discountType) === couponHelper.ABSOLUTE) {
        discountedAmount = discountValue;
        $scope.vm.displayedDiscount = '-' + discountValue.toString();
      } else if (_.toLower(discountType) === couponHelper.RELATIVE) {
        discountedAmount = Math.round($scope.vm.reserveDetails.payment.total * discountValue);
        $scope.vm.displayedDiscount = '-' + (Math.round(discountValue * 100)).toString() + '%';
      }
      // lost payment type here, manual
      if (discountedAmount > $scope.vm.reserveDetails.payment.total) {
        $scope.vm.reserveDetails.payment.total = 0;
        $scope.vm.reserveDetails.payment.stripeTotal = 0;
      } else {
        $scope.vm.reserveDetails.payment.total -= discountedAmount;
        $scope.vm.reserveDetails.payment.stripeTotal -= discountedAmount * 100;
      }
      $scope.vm.points = parseInt($scope.vm.reserveDetails.payment.total / $scope.vm.expPoint.pointsCurrencyRatio * $scope.vm.expPoint.pointsMultiplier);
    }
    // if ($scope.vm.couponCode === 'BACKDOOR') {
    //   _applyDiscount(couponHelper.ABSOLUTE, 100);
    // }
    $scope.vm.validateCouponPromise = APISrvc.validateCouponCode(
        $scope.vm.couponCode,
        cbModelDate.create(moment($scope.vm.reserveDetails.reservation.startDate)),
        cbModelDate.create(moment($scope.vm.reserveDetails.reservation.endDate)),
        $scope.vm.reserveDetails.guest.email,
        cbModelRoomType.create($scope.vm.reserveDetails.reservation.bookingType, $scope.vm.reserveDetails.reservation.numHour)
      ).then(function(res) {
        if (res.data.isValid) {
          _applyDiscount(res.data.discountType, res.data.discountValue);
        } else {
          console.log(res);
          throw new Error('invalid coupon Code');
        }
      })
      .catch(function() {
        $scope.vm.isCouponFailed = true;
      });
  };

  if ($scope.vm.couponCode) {
    $scope.vm.checkCoupon();
  }

  $scope.vm.clearCoupons = function() {
    const original = ReserveDetailsSrvc.get();

    if (_.get(original, 'payment.total', false) && _.get(original, 'payment.stripeTotal', false)) {
      $scope.vm.reserveDetails.payment.total = original.payment.total;
      $scope.vm.reserveDetails.payment.stripeTotal = original.payment.stripeTotal;

      $scope.vm.isCouponApplied = false;
      $scope.vm.couponCode = '';
      $scope.vm.points = parseInt($scope.vm.reserveDetails.payment.total / $scope.vm.expPoint.pointsCurrencyRatio * $scope.vm.expPoint.pointsMultiplier);
    } else {
      throw new Error('fail to clear coupon');
    }
  };

  // if (DiscountSrvc.earlyBird.enabled($scope.vm.startDate, $scope.vm.reserveDetails.reservation.numHour)) {
  //   $scope.vm.couponCode = DiscountSrvc.earlyBird.couponCode;
  //   $scope.vm.checkCoupon();
  // }
  //
  // if (DiscountSrvc.sleepyHour.enabled($scope.vm.startDate, $scope.vm.reserveDetails.reservation.numHour)) {
  //   $scope.vm.couponCode = DiscountSrvc.sleepyHour.couponCode;
  //   $scope.vm.checkCoupon();
  // }

  if ($scope.vm.reserveDetails.guests.length < 1) {
    FlowSrvc.entry();
  }
  const create_qrcode = function(text, typeNumber, errorCorrectionLevel, mode, mb) {
    qrcode.stringToBytes = qrcode.stringToBytesFuncs[mb];
    if (typeNumber == 0) {
      typeNumber = suggestTypeNumber(text);
    }

    const qr = qrcode(typeNumber || 4, errorCorrectionLevel || 'M');
    qr.addData(text, mode);
    qr.make();

    return qr;
  };

  function suggestTypeNumber(text) {
    const length = text.length;
    if (length <= 32) {
      return 3;
    } else if (length <= 46) {
      return 4;
    } else if (length <= 60) {
      return 5;
    } else if (length <= 74) {
      return 6;
    } else if (length <= 86) {
      return 7;
    } else if (length <= 108) {
      return 8;
    } else if (length <= 130) {
      return 9;
    } else if (length <= 151) {
      return 10;
    } else if (length <= 177) {
      return 11;
    } else if (length <= 203) {
      return 12;
    } else if (length <= 241) {
      return 13;
    } else if (length <= 258) {
      return 14;
    } else if (length <= 292) {
      return 15;
    } else {
      return 40;
    }
  }

  // function encodeImageFileAsURL(element) {
  //   let file = element.files[0];
  //   let reader = new FileReader();
  //   reader.onloadend = function() {
  //   let logo = document.getElementById('logo');
  //     logo.src = reader.result;
  //   }
  //   reader.readAsDataURL(file);
  // }

  function update_qrcode(link) {
    const text = link.replace(/^[\s\u3000]+|[\s\u3000]+$/g, '');
    const t = '0';
    const e = 'Q';
    const m = 'Byte';
    const mb = 'UTF-8';
    const qr = create_qrcode(text, t, e, m, mb);
    const size = '250';
    const consumer = false;

    const canvas = document.getElementById('payCodeCanvas');
    const ctx = canvas.getContext('2d');
    const logo = document.getElementById('logo');

    canvas.width = size;
    canvas.height = size;
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawPayCode(qr, canvas, 7, logo, consumer);
  }

  function _getTokenFromResponse(results) {
    const token = results[0];
    if (token) {
      // in Dev no stripe Token
      console.log('Got Stripe token: ' + token.id);
      $scope.vm.disablePaymentBtn = true;
    }
    return token;
  }

  function _reserve(token) {
    // API to persist
    // unless we upate price here
    console.log('in reverse');
    if (token && $scope.vm.payby == 'Credit Card') {
      $scope.vm.exp3Obj.payment.stripeToken = token;
    }
    if (token && $scope.vm.payby == 'PayMe') {
      // paymentRequestId for lambda mapping
      // stripeToken for backend to use
      $scope.vm.exp3Obj.payment.paymentRequestId = token;
    }
    const exp3Obj = $scope.vm.exp3Obj;
    return APISrvc.reserve(exp3Obj)
      .then(_.property('data'))
      .then(function(data) {
        console.log(data, 'reservation data for EXP3');
        ReserveDetailsSrvc.update({
          reservation: {
            pmsReservationId: data.cloudbeds[0].reservationID,
          },
        });
        if ($scope.vm.payby == 'PayMe') {} else {
          FlowSrvc.next();
        }
        return true;
      });
  }
  $scope.vm.toggleCouponMode = function() {
    $scope.vm.isCouponMode = !$scope.vm.isCouponMode;
  };
  $scope.formatDate = function(cbDate) {
    return moment(cbDate).format('YYYY.MM.DD (ddd)');
  };

  $scope.vm.uuid = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  $scope.vm.doCheckout = function() {
    // disabled check at case of coupon
    const guests = $scope.vm.reserveDetails.guests[0];
    $scope.vm.reserveDetails.couponCode = $scope.vm.isCouponApplied ? $scope.vm.couponCode : '';
    $scope.vm.reserveDetails.payment.payby = $scope.vm.payby;
    $scope.vm.exp3Obj = {
      oid: $scope.vm.uuid(),
      loginName: guests.firstName + guests.lastName,
      email: guests.email,
      siteName: APP_CONFIG.OPERATIONS.EXP_SITE_ID,
      startTime: moment($scope.vm.reserveDetails.reservation.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment($scope.vm.reserveDetails.reservation.endDate).format('YYYY-MM-DD HH:mm:ss'),
      price: $scope.vm.reserveDetails.payment.total,
      roomTypeID: $scope.vm.reserveDetails.reservation.roomTypeID,
      guests: $scope.vm.reserveDetails.guests,
      payment: $scope.vm.reserveDetails.payment,
      reservation: $scope.vm.reserveDetails.reservation,
      tokenType: APP_CONFIG.LAMBDA.TOKEN_TYPE,
      willCloudbedsHandleHourly: APP_CONFIG.OPERATIONS.WILL_CLOUDBEDS_HANDLE_HOURLY,
      willCloudbedsHandleOverNight: APP_CONFIG.OPERATIONS.WILL_CLOUDBEDS_HANDLE_OVERNIGHT,
      originalPrice: $scope.vm.originalPrice,
      couponCode: $scope.vm.couponCode,
      expPoint: $scope.vm.points,
      spacePrice: $scope.vm.reserveDetails.spacePrice,
    };
    if ($scope.vm.payby == 'PayMe') {
      $scope.vm.dimmed = true;
      $scope.vm.loading = true;
      // const appSuccessCallback = $scope.vm.isNotMobile?`${APP_CONFIG.SITE_URL}/payme?oid=${$scope.vm.exp3Obj.oid}`:`${APP_CONFIG.SITE_URL}/booking/completed?oid=${$scope.vm.exp3Obj.oid}`;
      const appSuccessCallback = `${APP_CONFIG.SITE_URL}/booking/completed?oid=${$scope.vm.exp3Obj.oid}`;
      const appFailCallback = `${APP_CONFIG.SITE_URL}/error/payme?oid=${$scope.vm.exp3Obj.oid}`;
      const notificationUri = 'https://uehara.serverless.exp.is/payme/notification';
      const paymentRequestObj = {
        'totalAmount': $scope.vm.exp3Obj.price,
        'currencyCode': 'HKD',
        'appSuccessCallback': appSuccessCallback,
        'appFailCallback': appFailCallback,
        'effectiveDuration': 600,
        'notificationUri': notificationUri,
        'merchantData': $scope.vm.exp3Obj,
      };
      if ($scope.vm.isNotMobile) {
        delete paymentRequestObj.appSuccessCallback;
        delete paymentRequestObj.appFailCallback;
      }
      PayMeSrvc.createPaymentRequest(paymentRequestObj).then(function(res) {
        $scope.vm.loading = false;
        console.log(res.data);
        if (res.data.errors) {
          $scope.vm.paymeFailMsg = 'EA002';
        }
        $scope.vm.paymeResponse = res.data;
        if ($scope.vm.isNotMobile) {
           const requestId = _.get($scope.vm.paymeResponse, 'paymentRequestId');
           _reserve(requestId)
            .then((data) => {
              console.log(data);
              // $scope.vm.paymelogo = res.data.businessLogos.normal;
              // console.log($scope.vm.paymelogo);
              update_qrcode(res.data.webLink);
              $scope.vm.timerId = setTimeout(function tick() {
                $scope.vm.intervalTimerId = setInterval($scope.vm.checkPayMePaymentStatus, 5000, $scope.vm.intervalPayMeCallBack);
              }, 30000);
           })
            .catch(function(err) {
              console.log('Error: ', err);
              if (err) {
                FlowSrvc.error(JSON.stringify(err));
              }
          });
        }
      });
    } else {
      $scope.vm.reservePromise = null;
      if ($scope.vm.reserveDetails.payment.stripeTotal === 0) {
        if (!$scope.vm.isCouponApplied || $scope.vm.isCouponFailed) {
          return FlowSrvc.error('Wrong Payment Amount');
        }
        $scope.vm.payPromise = Promise.any([null]);
      } else {
        $scope.vm.payPromise = StripeSrvc.doCheckout({
          amount: $scope.vm.reserveDetails.payment.stripeTotal,
          email: $scope.vm.reserveDetails.guest.email,
          currency: APP_CONFIG.OPERATIONS.CURRENCY,
          description: '',
        }).then(_getTokenFromResponse);
      }
      $scope.vm.reservePromise = $scope.vm.payPromise
        .then(_reserve)
        .catch(function(err) {
          console.log('Error: ', err);
          if (_.get(err, 'data.paymentStatus', false) === 0) {
            $scope.vm.disablePaymentBtn = false;
            _.set($scope.vm, 'stripeFailMsg', err.data.msg);
          } else if (err) {
            FlowSrvc.error(JSON.stringify(err));
          }
        });
    }
  };

  $scope.vm.redirectPayMe = function() {
    $scope.vm.loading = true;
    const requestId = _.get($scope.vm.paymeResponse, 'paymentRequestId');
    if (requestId) {
      console.log('do reserve');
      _reserve(requestId)
        .then((data) => {
          console.log(data);
          // $window.open($scope.vm.paymeResponse.appLink, '_blank');
          $window.location.href = $scope.vm.paymeResponse.webLink;
        });
    }
  };

  $scope.vm.isDEV = process.env.NODE_ENV === 'DEV';
  if ($scope.vm.isDEV) {
    $scope.vm._reserve = function(token) {
      return _reserve(token)
        .catch(function(err) {
          $scope.vm.disablePaymentBtn = false;
          FlowSrvc.next();
          // silent failure
          console.log(err);
        });
    };
  }
  $scope.vm.STRIPE_API_KEY = APP_CONFIG.STRIPE_API_KEY;
};

ConfirmCtrl.$inject = [
  '$scope',
  'FlowSrvc',
  'ReserveDetailsSrvc',
  'DiscountSrvc',
  'CouponSrvc',
  'APISrvc',
  'config',
  'ModalService',
  'StripeSrvc',
  'PayMeSrvc',
  '$rootScope',
  '$window',
];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {},
  controller: ConfirmCtrl,
};