const template = require('./details.html');
require('./details.css');
const auth0Mapper = require('../../../lib/mapper/details_exp3');
// const timeUtil = require('../../time_util.js');
const countryCodes = require('../../../config/restCountries/listOfCodes.json');
const phoneCodes = require('../../../config/restCountries/phoneCodes.json');

const DetailsCtrl = function($scope, $location, store, FlowSrvc, ReserveDetailsSrvc, UserSrvc) {
  // console.log('------- getting UserSrvc.get()');
  // console.log(UserSrvc.get());
  // console.log(UserSrvc.validate());
  console.log(ReserveDetailsSrvc.get());
  $scope.vm.phonepattern = /^[0-9]+$/;
  $scope.vm.bookingSteps = APP_CONFIG.BOOKING_STEPS_LABELS;
  $scope.vm.bookingStepsUrl = APP_CONFIG.BOOKING_STEPS_URLS;
  $scope.vm.reserveDetails = ReserveDetailsSrvc.get();
  $scope.vm.bookingType = $scope.vm.reserveDetails.reservation.bookingType;
  $scope.vm.today = moment();
  $scope.vm.dob = {};
  $scope.vm.dob.yearRange = _.range(1900, (this.today.year() - APP_CONFIG.OPERATIONS.MIN_GUEST_AGE)).reverse();
  $scope.vm.dob.monthRange = _.range(1, 13);
  // $scope.$watch('vm.reserveDetails.guests', function(n, o) {
  //   console.log(n, o);
  //   if (!_.isEqual(n, o)) {
  //     ReserveDetailsSrvc.update({guests: n});
  //   }
  // }, true);

  $scope.vm.countryCodes = countryCodes;
  $scope.vm.phoneCodes = phoneCodes;

  _prefillIfLoggedIn();

  function _prefillIfLoggedIn() {
    const guest = $scope.vm.reserveDetails.guest;
    const user = UserSrvc.get();
    if (user) {
      _.merge(guest, auth0Mapper.asGuest(user));
    }
    // if (user) {
    //   _.merge(guest, user);
    // }

    // merge DOB to selection box
    console.log($scope.vm.reserveDetails);
    console.log(_.parseInt(_.get($scope.vm.reserveDetails.guest, 'dob_year')));
    if (_.includes($scope.vm.dob.yearRange, _.parseInt(_.get($scope.vm.reserveDetails.guest, 'dob_year')))) {
      _.set($scope.vm.dob, 'year', _.parseInt(_.get($scope.vm.reserveDetails.guest, 'dob_year')));
      _.set($scope.vm.dob, 'month', _.parseInt(_.get($scope.vm.reserveDetails.guest, 'dob_month')));
      _.set($scope.vm.dob, 'day', _.parseInt(_.get($scope.vm.reserveDetails.guest, 'dob_day')));
    } else {
      console.log('dob year not valid');
    }
    // if (_.get($scope.vm.reserveDetails.guest, 'dob')) {
    //   const dob = moment(_.get($scope.vm.reserveDetails.guest, 'dob'), 'YYYY-M-DD');
    //   _.set($scope.vm.dob, 'year', _.parseInt(dob.format('YYYY')));
    //   _.set($scope.vm.dob, 'month', _.parseInt(dob.format('M')));
    //   _.set($scope.vm.dob, 'day', _.parseInt(dob.format('DD')));
    // }
    // $scope.vm.reserveDetails.guest.dob = moment('2020-11-28').format();
    // if not LoggedIn but bookingType is hourly, set default country
    if ($scope.vm.reserveDetails.reservation.bookingType === 'hour') {
      $scope.vm.reserveDetails.guest.country = 'HK';
    }
  }

  // $scope.vm.guestDobInput = moment(_.get($scope, 'vm.reserveDetails.guest.dob')).toDate();

  $scope.getDayRange = function() {
    const selectedYear = $scope.vm.dob.year || $scope.vm.today.year();
    const selectedMonth = $scope.vm.dob.month || $scope.vm.today.month();
    const maxDay = moment(selectedYear+'-'+selectedMonth, 'YYYY-MM').daysInMonth();
    // console.log("day range", _.range(1, (_.parseInt(maxDay) + 1)));
    return _.range(1, (maxDay + 1));
  };
  // For timezone
  // $scope.$watch('vm.guestDobInput', function() {
  //   // TODO strange bug +1 day, become sth like this, better store as moment Fri Nov 18 1988 00:00:00 GMT-0600 (CST)
  //   $scope.vm.reserveDetails.guest.dob = moment($scope.vm.guestDobInput).format();
  // });
  $scope.$watch('vm.dob.day', function() {
    const selectedYear = $scope.vm.dob.year;
    const selectedMonth = $scope.vm.dob.month;
    const selectedDay = $scope.vm.dob.day;

    const dob_arr = [selectedYear, selectedMonth - 1, selectedDay];
    $scope.vm.reserveDetails.guest.dob = moment(dob_arr).format();
  });

  // @disabled iframe->details feature
  // var query = $location.search();
  // if (query.reservation) {
  //   $scope.vm.reservation = JSON.parse(query.reservation);
  // }
  // helper for dumb angular expression
  $scope.isErr = function(form, field) {
    return Object.keys(form[field].$error).length > 0 && form.$submitted;
  };

  $scope.dobValidate = function() {
    return $scope.vm.dob.year && $scope.vm.dob.month && $scope.vm.dob.day;
  };

  $scope.submit = function() {
    $scope.vm.reserveDetails.guests[0].phone.split(' ').join('');
    ReserveDetailsSrvc.update({
      guests: $scope.vm.reserveDetails.guests,
    });
    FlowSrvc.next();
  };
  $scope.$on('login.success', function(data) {
    console.log('update profile');
    console.log(data);
    _prefillIfLoggedIn();
  });
};

DetailsCtrl.$inject = ['$scope', '$location', 'store', 'FlowSrvc', 'ReserveDetailsSrvc', 'UserSrvc'];

module.exports = {
  template: template,
  controllerAs: 'vm',
  bindings: {
  },
  controller: DetailsCtrl,
};
