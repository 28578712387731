/* global _*/
function RoomAvailabilitySrvc($http, config) {
  /**
  * startDate
  * endDate
  * roomType
  * guestCount
  **/
  let cloudbedsRoomType = [];
  const setCloudbedsRoomType = function(data) {
    cloudbedsRoomType = data;
  };
  const getCloudbedsRoomType = function() {
    console.log(cloudbedsRoomType);
    return cloudbedsRoomType;
  };
  const checkAvailability = function(details) {
    return $http.post('config.API_PATH + '/check-availability, details);
  };
  const checkAvailabeRoomType = function(details) {
    return $http.post(
        APP_CONFIG.LAMBDA.CHECK_AVAILABILITY_ROOM_TYPE,
        details);
  };
  const checkHourlyRoomRate = function(location) {
    return $http.get(
        'https://os.exp.is/api/roomprices/location_name/' + location);
  };

  const getUserDetails = function(body) {
    console.log(body);
    return $http.post('https://uehara.serverless.exp.is/eber/checkhmac', body);
  };

  return {
    checkAvailability: checkAvailability,
    checkAvailabeRoomType: checkAvailabeRoomType,
    checkHourlyRoomRate: checkHourlyRoomRate,
    getCloudbedsRoomType: getCloudbedsRoomType,
    setCloudbedsRoomType: setCloudbedsRoomType,
    getUserDetails: getUserDetails,
  };
}

RoomAvailabilitySrvc.$inject = ['$http', 'config'];

module.exports = RoomAvailabilitySrvc;
